/* General Reset */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #0d0d0d;
  color: #fff;
  line-height: 1.6;
}

a {
  text-decoration: none; /* Remove underlines */
  color: inherit; /* Ensure links use the same text color */
}

a:visited {
  color: inherit; /* Remove visited link styling */
}

a:hover {
  text-decoration: none; /* Prevent hover underline */
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Consistent 4px scaling system */
}

/* Dots Background */
.dots-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(circle, #424242 2px, transparent 2px);
  background-size: 48px 48px;
  z-index: -1;
}

/* Design Guide Lines */
.design-guides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Extend to full height of content */
  pointer-events: none;
}

.design-guides .line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  top: 120px;
}

.design-guides .vertical-line {
  position: absolute;
  height: 100%; /* Extend the full height of content */
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  left: calc(10% - 32px); /* Adjust spacing between guide and content */
  top: 0; /* Ensure it starts at the top */
}

h1,
h2,
p {
  margin: 0; /* Remove default browser margins */
  padding: 0;
}

/* Hero Section */
.hero {
  margin-top: 144px;
  text-align: left;
  width: 80%; /* Match container width */
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.hero .title {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0px; /* Explicitly remove margins */
  line-height: 1.2; /* Adjust spacing between lines */
}

.hero .subtitle {
  font-size: 1.5rem;
  color: #aaa;
  margin-top: 4px; /* Use consistent 4px scaling system */
}

.hero .links {
  display: flex;
  gap: 8px;
  margin-top: 8px; /* Add consistent spacing */
}

.hero .links a {
  display: inline-block;
  padding: 8px 16px; /* Use 4px scaling system */
  color: white;
  background-color: #000;
  border: 2px solid white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 0px;
  transition: all 0.3s ease;
}

.hero .links a:hover {
  background-color: #e0e0e0;
  color: #0d0d0d;
}
/* Bio Section */
.bio {
  margin: 32px 0px; /* Use 32px spacing for section margins */
  text-align: left;
  width: 80%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.bio p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #ccc;
}

/* Projects Section */
.projects {
  margin-top: 0px;
  text-align: left;
  width: 80%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20%;
}

.projects h2 {
  font-size: 2.5rem;
  margin-bottom: 8px;
}

.project-grid {
  position: relative; /* Establish a positioning context */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0; /* No spacing between projects */
  border: 1px solid #fff;
  overflow: hidden; /* Prevent pattern overflow */
}

.project-grid::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1) 10px,
    rgba(255, 255, 255, 0.05) 10px,
    rgba(255, 255, 255, 0.05) 20px
  );
  z-index: -1; /* Ensure the pattern stays behind the grid */
}

.project-grid::before {
  animation: grid-move 10s linear infinite;
}

@keyframes grid-move {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100px 100px;
  }
}

.project-item {
  border-right: 1px solid #fff; /* Borders between projects */
  border-bottom: 1px solid #fff;
  background-color: #000;
  padding: 16px; /* Use consistent padding */
  text-align: left;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.project-item:hover {
  background-color: #222;
}

.project-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 8px;
}

.project-item h3 {
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #fff;
}
.project-thumbnail {
  width: 100%;
  height: auto;
  margin-bottom: 4px;
}

.project-item h3 {
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #fff;
}

/* Project Detail */
.project-detail {
  background-color: black;
  padding: 24px;
  color: white;
  border: 1px solid;
  margin-top: 0px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.project-detail img {
  max-width: 400px;
  margin-bottom: 8px;
  margin-left: auto;
  overflow: hidden;
  align-self: center;
}

.project-detail h3 {
  margin: 0px;
}

.project-detail p {
  font-size: 1rem;
  line-height: 1.8;
  color: #ccc;
  margin-bottom: 16px;
}
.project-detail .gallery {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 8px 0;
  margin-bottom: 16px;
}

.project-detail .gallery img {
  flex-shrink: 0;
  max-width: 480px;
  border-radius: 0px;
  border: 1px solid;
  border-color: #fff;
}

.project-header {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align content to the left */
  margin-bottom: 16px; /* Space below the header */
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  padding: 8px 16px;
  background: none;
  border: 2px solid #fff;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button .back-icon {
  width: 16px;
  height: 16px;
  fill: #fff;
  transition: transform 0.3s ease, fill 0.3s ease;
}

.back-button:hover {
  background-color: #fff;
  color: #000;
}

.back-button:hover .back-icon {
  fill: #000;
  transform: translateX(-4px); /* Slide the icon slightly left */
}

.project-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 8px; /* Space between back button and title */
  margin-bottom: 0; /* Remove extra bottom margin */
  color: #fff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .container {
    width: 90%;
    padding: 0 8px;
  }

  .hero .title {
    font-size: 3rem;
  }

  .hero .subtitle {
    font-size: 1.2rem;
  }

  .projects h2 {
    font-size: 2rem;
  }

  .project-grid {
    gap: 0;
  }

  .project-detail {
    width: 90%;
  }

  .project-detail img {
    max-width: 220px;
  }

  .project-detail .gallery img {
    width: 300px;
  }

  .project-item {
    padding: 12px; /* Reduce padding on smaller screens */
  }
}
